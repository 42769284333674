// 👇️ ts-nocheck disables type checking for entire file
// eslint-disable @typescript-eslint/ban-ts-comment
// @ts-nocheck

import * as React from 'react';
import styled from 'styled-components';

import Web3Modal from 'web3modal';
// @ts-ignore
import WalletConnectProvider from '@walletconnect/web3-provider';

import Loader from './components/Loader';
import Header from './components/Header'
import ConnectButton from './components/ConnectButton';
import Denial from './components/Denial'
import Store from './components/Store'
import Footer from './components/Footer'

import { Web3Provider } from '@ethersproject/providers';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import Torus from "@toruslabs/torus-embed";
import { getChainData } from './helpers/utilities';

import './style.css'
import dataset from './dataset.js'




// interface IAppState {
//   fetching: boolean;
//   address: string;
//   library: any;
//   connected: boolean;
//   chainId: number;
//   pendingRequest: boolean;
//   result: any | null;
//   electionContract: any | null;
//   info: any | null;
//   hasProvider: boolean;
//   menuOpen: boolean;
//   foundProvider: boolean;
// }

const INITIAL_STATE = {
  address: '',
  chainId: 1,
  connected: false,
  electionContract: null,
  fetching: false,
  foundProvider: true,
  hasProvider: false,
  info: null,
  library: null,
  menuOpen: false,
  pendingRequest: false,
  result: null,
};

class App extends React.Component{
  // @ts-ignore
  // public web3Modal: Web3Modal;
  // public state: IAppState;
  // public provider: any;

  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    };
    try{

      this.web3Modal = new Web3Modal({
        network: this.getNetwork(),
        cacheProvider: true,
        providerOptions: this.getProviderOptions(),
      });
    }catch(err){
      console.log('werb3modal error:', err)
    }
  }

  componentDidMount() {
    // ! not recommended to connect without user interaction
    // if (this.web3Modal.cachedProvider) {
    //   this.onConnect();
    // }
    this.setState({...this.state, foundProvider: true})

    // console.log('v1.0.8')
    const IS_CONNECTED = sessionStorage.getItem('CHIPTOS_CONNECTED')

    if(IS_CONNECTED && IS_CONNECTED === 'true'){
      console.log('RECONNECTING!!!')
      this.onConnect()
    }
  }

  onConnect = async () => {

    sessionStorage.setItem('CHIPTOS_CONNECTED', 'true')

    // ! catch if there is no provider
    try{
      this.provider = await this.web3Modal.connect();
      // console.log('provider found...')
    }catch(err){
      console.log('no provider found')
      this.setState({...this.state, foundProvider: false})
    }


    // ! only proceed if a provider is available
    if(this.provider){

      const library = new Web3Provider(this.provider);
      
      const network = await library.getNetwork();
      
      const address = this.provider.selectedAddress ? this.provider.selectedAddress : this.provider.accounts[0];
      
      await this.setState({
        library,
        chainId: network.chainId,
        address,
        connected: true
      });
      
      await this.subscribeToProviderEvents(this.provider);
      
    }
  };

  subscribeToProviderEvents = async (provider) => {
    if (!provider.on) {
      return;
    }

    provider.on("accountsChanged", this.changedAccount);
    provider.on("networkChanged", this.networkChanged);
    provider.on("close", () => {
      sessionStorage.setItem("CHIPTOS_CONNECTED", 'false');
      this.close();
    });

    // await this.web3Modal.off('accountsChanged');
  };

  async unSubscribe(provider) {
    // Workaround for metamask widget > 9.0.3 (provider.off is undefined);
    window.location.reload();
    if (!provider.off) {
      return;
    }

    provider.off("accountsChanged", this.changedAccount);
    provider.off("networkChanged", this.networkChanged);
    provider.off("close", this.close);
  }

  changedAccount = async (accounts) => {
    if(!accounts.length) {
      // Metamask Lock fire an empty accounts array 
      await this.resetApp();
    } else {
      await this.setState({ address: accounts[0] });
    }
  }

  networkChanged = async (networkId) => {
    const library = new Web3Provider(this.provider);
    const network = await library.getNetwork();
    const chainId = network.chainId;
    await this.setState({ chainId, library });
  }
  
  close = async () => {
    this.resetApp();
  }  

  getNetwork = () => getChainData(this.state.chainId).network;

  getProviderOptions = () => {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: process.env.REACT_APP_INFURA_ID
        }
      },
      walletlink: {
        package: CoinbaseWalletSDK, // Required
        options: {
          appName: "Chiptos", // Required
          infuraId: process.env.REACT_APP_INFURA_ID, // Required
          rpc: "", // Optional if `infuraId` is provided; otherwise it's required
          chainId: 1, // Optional. It defaults to 1 if not provided
          darkMode: false // Optional. Use dark theme, defaults to false
        }
      },
      torus: {
        package: Torus, // required
        options: {
          networkParams: {
            // host: "https://localhost:8545", // optional
            // chainId: 1337, // optional
            // networkId: 1337 // optional
          },
          config: {
            // buildEnv: "development" // optional
          }
        }
      }
    };
    return providerOptions;
  };

  resetApp = async () => {
    await this.web3Modal.clearCachedProvider();
    localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
    localStorage.removeItem("walletconnect");
    sessionStorage.setItem('CHIPTOS_CONNECTED', 'false')
    await this.unSubscribe(this.provider);

    this.setState({ ...INITIAL_STATE });

  };





  toggleMenu = () => {
    this.setState({...this.state, menuOpen: !this.state.menuOpen})
    // console.log('toggle menu')
  }

  render = () => {
    const {
      address,
      connected,
      chainId,
      fetching
    } = this.state;

  
    return (
      <div className='main-container'>


          <div 
          className='mobile-menu' 
          // tslint:disable-next-line
          style={{
            transform: this.state.menuOpen ? 'translateX(0vw)' : 'translateX(100vw)',
            WebkitTransform: this.state.menuOpen ? 'translateX(0vw)' : 'translateX(100vw)',
            MozTransform: this.state.menuOpen ? 'translateX(0vw)' : 'translateX(100vw)',
            OTransform: this.state.menuOpen ? 'translateX(0vw)' : 'translateX(100vw)',
            msTransform: this.state.menuOpen ? 'translateX(0vw)' : 'translateX(100vw)',
            zIndex: this.state.menuOpen ? '4' : '0',
            pointerEvents: this.state.menuOpen ? 'auto' : 'none',
          }}
          >
            <div className='bg' 
            style={{
              transform: this.state.menuOpen ? 'translateX(0vw)' : 'translateX(-100vw)',
            }}
            >
              <div className="menu-bg-fade" />
              <div className='menu-button-layer'>

                <button onClick={this.toggleMenu} className='close-button' />

                <div className='nav-link-row'>
                  <a href={dataset.aboutLink} className='nav-link'>About</a>
                  <a href={dataset.docsLink} className='nav-link'>Docs</a>
                  <a href={dataset.visionLink} className='nav-link'>Vision</a>
                  <a href={dataset.visionLink} className='nav-link'>Website</a>
                </div>

                <div className="nav-icon-row"
                  style={{
                    pointerEvents: this.state.menuOpen ? 'auto' : 'none',
                  }}>
                  <a href={dataset.etherscan} className='etherscan-nav nav-icon ' />
                  <a href={dataset.opensea} className='opensea-nav nav-icon ' />
                  <a href={dataset.looksrare} className='looksrare-nav nav-icon ' />
                  <a href={dataset.twitter} className='twitter-nav nav-icon ' />
                  <a href={dataset.discord} className='discord-nav nav-icon ' />
                </div>

              </div>
            </div>
          </div>



          <Header
            toggleMenu={this.toggleMenu}
            menuOpen={this.state.menuOpen}
            connected={connected}
            address={address}
            chainId={chainId}
            killSession={this.resetApp}
          />
          <div className='content'>
          {fetching ? (
              <Loader />
          ) : (
            <>

{(!this.state.foundProvider && !this.state.address) 
            ?
              <div className='wrong-chain'>
                <h2>No Provider?</h2>
                <h4>Please install a wallet provider.</h4>
                <button onClick={this.onConnect}>Try Again</button>
              </div>

            : (this.state.foundProvider && this.state.chainId !== 1) 

                ?
                <div className='wrong-chain'>
                  <h2>Incorrect Chain</h2>
                  <h4>Please change to Ethereum Mainnet.</h4>
                  <button onClick={this.onConnect}>Try Again</button>
                </div>

                :

                  <>
                    <ConnectButton connected={this.state.connected} onClick={this.onConnect} /> 
                    
                    
                      <Store 
                        library={this.state.library}
                        web3modal={this.web3Modal}
                        connected={this.state.connected}
                        address={this.state.address}
                        chainId={this.state.chainId}
                      /> 
                  </>
            
              }

               
              
            </>
          )}
          </div>

          <Footer />
      </div>
    );
  };
}

export default App;
