/* tslint:disable */
const dataset = {
    etherscan: 'https://etherscan.io/address/0x5955373CC1196fD91A4165C4c5c227B30a3948f9',
    opensea:  'https://opensea.io/collection/chiptosx',
    looksrare:  'https://looksrare.org/collections/0x5955373CC1196fD91A4165C4c5c227B30a3948f9',
    
    discord:  'https://discord.gg/chiptos',
    twitter:  'https://twitter.com/ChiptosX',
    
    price: '.088 ETH',
    
    aboutLink: 'https://docs.chiptosnft.com/introduction/history',
    docsLink: 'https://docs.chiptosnft.com/',
    visionLink: 'https://docs.chiptosnft.com/introduction/vision',

}
export default dataset