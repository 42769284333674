import * as React from 'react'
import styled from 'styled-components';
import dataset from '../dataset.js'
import Web3 from 'web3'
import Web3Modal from 'web3modal';
import toast from 'react-hot-toast'
    /* tslint:disable */
import abi from '../abi.json'
import { generateMerkleProof, verifyProof } from 'src/merkleFunc';

const Store = (props:any) => {

    
    
    const [remaining, setRemaining] = React.useState(0)
    const [freeRemaining, setFreeRemaining] = React.useState(0)
    const [isPreSale, setIsPreSale] = React.useState(true)

    const [holderSaleAmount, setHolderSaleAmount] = React.useState(0)
    const [preSaleAmount, setPreSaleAmount] = React.useState(0)
    const [publicSaleAmount, setPublicSaleAmount] = React.useState(0)
    const [maxHolderAvalable, setMaxHolderAvailable] = React.useState(0)
    const [maxPresaleAvailable, setMaxPresaleAvailable] = React.useState(0)
    const [isWhitelisted, setIsWhitelisted] = React.useState(false)
    const [isPresaleActive, setIsPresaleActive] = React.useState(false)


    const [txHash, setTxHash] = React.useState('0x00')


    const max = {
        pre: 2,
        public: 10,
    }

    const inc_holder = () => holderSaleAmount < maxHolderAvalable && setHolderSaleAmount(n => n + 1)
    const dec_holder = () => holderSaleAmount > 0 && setHolderSaleAmount(n => n - 1)
    
    const inc_presale = () => preSaleAmount < max.pre && setPreSaleAmount(n => n + 1)
    const dec_presale = () => preSaleAmount > 0 && setPreSaleAmount(n => n - 1)

    const inc_public = () => publicSaleAmount < max.public && setPublicSaleAmount(n => n + 1)
    const dec_public = () => publicSaleAmount > 0 && setPublicSaleAmount(n => n - 1)
    const intervalRef = React.useRef<any>(null)

    /* tslint:disable */
    const web3 =  new Web3(Web3.givenProvider)
    const ABI: any = abi
    const Contract = new web3.eth.Contract(ABI, '0x5955373CC1196fD91A4165C4c5c227B30a3948f9')

    const isInWhitelist = () => {
        const bool = verifyProof(props.address)
        // console.log('current user:', props.address)
        // console.log('user is in whitelist:', bool)
        setIsWhitelisted(bool)
    }




    const reloadData = () => {

        Contract.methods.preSaleIsActive()
        .call()
        .then((data:any) => {
            // console.log('presaleActive:', data)
            // setIsPreSale(!data)
            setIsPresaleActive(data)
        })
        .catch((err:any) => console.error('saleIsActive | ERROR:', err))


        Contract.methods.saleIsActive()
        .call()
        .then((data:any) => {
            // console.log('isPresale:', !data)
            setIsPreSale(!data) 
        })
        .catch((err:any) => console.error('saleIsActive | ERROR:', err))


        Contract.methods.totalSupply()
        .call()
        .then((data:any) => {
            // console.log('call to total supply:', data)
            setRemaining(7680 - data)
        })
        .catch((err:any) => console.error('totalSupply | ERROR:', err))

    
        Contract.methods.numFreeAvailableToMint(props.address)
        // .call()
        .call({from: props.address})
        .then((data:any) => {
            // console.log('num free available:', data)
            setFreeRemaining(data)
        })
        .catch((err:any) => console.error('numFreeAvailableToMintt | ERROR:', err))

    
        Contract.methods.numHolderAvailableToMint(props.address)
        .call({from: props.address})
        // .call()
        .then((data:any) => {
            // console.log('num holder available:', data)
            setMaxHolderAvailable(data)
        })
        .catch((err:any) => console.error('numHolderAvailableToMint | ERROR:', err))

    
    
        Contract.methods.numMinted(props.address)
        .call({from: props.address})
        // .call()
        .then((data:any) => {
            // console.log('num holder available:', data)
            setMaxPresaleAvailable(2 - data)
        })
        .catch((err:any) => console.error('numMinted | ERROR:', err))

    }
    
    const claimFree = () => {

        const runFunc = () => {
            return new Promise((res, rej) => {
            
                // claim all remaining free
                Contract.methods.holderFree(freeRemaining)
                .send({
                    from: props.address, 
                    value: 0
                })
                .then((data:any) => {
                    // console.log('num holder available:', data)
                    setMaxPresaleAvailable(2 - data)
                    reloadData()
                    res(data.transactionHash)
                })
                .catch((err:any) => {
                    console.log('claimFree | ERROR:', err)
                    if(err.message.toLowerCase().includes('denied')){
                        rej('Transaction Denied.')
                    }else{
                        rej('Transaction Error.')
                    }
                })
            })
        }



        
        toast.promise(
            runFunc(),
             {
               loading: 'Minting...',
               success: (data) => <b>Mint Succesful<br /><a className='tx-link' href={`https://etherscan.io/tx/${data}`}>View Transaction</a></b>,
               error: <b>Transaction Error</b>,
             },
             {
                style:{
                    textAlign: 'center',
                    lineHeight: '2rem'
                }
            }
        );
        
    }
    
    const mintHolder = () => {

        const runFunc = () => {
            return new Promise((res, rej) => {

                const totalPrice = (holderSaleAmount * 88_000_000_000_000_000).toString()
                // 88_000_000_000_000_000

                console.log(`holder mint quantity ${holderSaleAmount} at price ${totalPrice}`)

                Contract.methods.holderPresale(holderSaleAmount)
                .send({
                    from: props.address, 
                    value: totalPrice
                })
                .then((data:any) => {
                    // console.log('num holder available:', data)
                    setMaxPresaleAvailable(2 - data)
                    reloadData()
                    res(data.transactionHash)
                })
                .catch((err:any) => {
                    console.log('mintHolder | ERROR:', err)
                    if(err.message.toLowerCase().includes('denied')){
                        rej('Transaction Denied.')
                    }else{
                        rej('Transaction Error.')
                    }
                })
            })
        }


        toast.promise(
            runFunc(),
             {
               loading: 'Minting...',
               success: (data) => <b>Mint Succesful<br /><a className='tx-link' href={`https://etherscan.io/tx/${data}`}>View Transaction</a></b>,
               error: <b>Transaction Error</b>,
             },
             {
                style:{
                    textAlign: 'center',
                    lineHeight: '2rem'
                }
            }
        );

    }

    const mintPublicSale = () => {

        const runFunc = () => {
            return new Promise((res, rej) => {

                const totalPrice = (publicSaleAmount * 88_000_000_000_000_000).toString()
                
                console.log(`public mint quantity ${publicSaleAmount} at price ${totalPrice}`)
                
                Contract.methods.publicMint(publicSaleAmount)
                .send({
                    from: props.address, 
                    value: totalPrice
                })
                .then((data:any) => {
                    console.log('look for hash:', data)
                    // console.log('num holder available:', data)
                    // setMaxPresaleAvailable(2 - data)
                    reloadData()
                    res(data.transactionHash)
                })
                .catch((err:any) => {
                    console.log('mintPublicSale | ERROR:', err)
                    if(err.message.toLowerCase().includes('denied')){
                        rej('Transaction Denied.')
                    }else{
                        rej('Transaction Error.')
                    }
                })
            })
        }


        toast.promise(
            runFunc(),
             {
               loading: 'Minting...',
               success: (data) => <b>Mint Succesful<br /><a className='tx-link' href={`https://etherscan.io/tx/${data}`}>View Transaction</a></b>,
               error: (data) => <b>{data}</b>,
             },
             {
                style:{
                    textAlign: 'center',
                    lineHeight: '2rem'
                }
            }
        );
    }

    const mintPreSale = () => {

        const runFunc = () => {
            return new Promise((res, rej) => {

                
                const merkleProof:any = generateMerkleProof(props.address)

                const totalPrice = (preSaleAmount * 88_000_000_000_000_000).toString() // this is ether amount

                console.log(`pre-sale mint quantity ${preSaleAmount} at price ${totalPrice}`)

                Contract.methods.presaleMint(merkleProof, preSaleAmount)
                .send({
                    from: props.address, 
                    // value: 0,
                    value: totalPrice
                })
                .then((data:any) => {
                    // console.log('num holder available:', data)
                    // setMaxPresaleAvailable(2 - data)
                    reloadData()
                    res(data.transactionHash)
                })
                .catch((err:any) => {
                    console.log('mintPreSale | ERROR:', err)
                    if(err.message.toLowerCase().includes('denied')){
                        rej('Transaction Denied.')
                    }else{
                        rej('Transaction Error.')
                    }
                })
                
            })
        }
            
        toast.promise(
            runFunc(),
             {
               loading: 'Minting...',
               success: (data) => <b>Mint Succesful<br /><a className='tx-link' href={`https://etherscan.io/tx/${data}`}>View Transaction</a></b>,
               error: <b>Transaction Error</b>,
             },
             {
                style:{
                    textAlign: 'center',
                    lineHeight: '2rem'
                }
            }
        );
        //
    }



    React.useEffect(()=>{

        setRemaining(0)
        setFreeRemaining(0)
        setHolderSaleAmount(0)
        setPreSaleAmount(0)
        setPublicSaleAmount(0)
        setMaxHolderAvailable(0)
        setMaxPresaleAvailable(0)

        if(props.connected === true && props.address && props.address !== ''){
            // console.log('CONNECTED =====================================================')
            isInWhitelist()
            reloadData()
            intervalRef.current = setInterval(reloadData, 30_000)
        }

        
        
        return () => {
            clearInterval(intervalRef.current)
        }
    }, [props])


        
        
        








    return(
        <div className='store-card' style={{
            transform: props.connected ? 'translateY(0vh)' : 'translateY(calc(100vh + 1000px))',
            border: props.connected ? '0px solid white' : '0px solid red',
        }}>

            {remaining === 0
            ? <>
                <div className="store-info" style={{
                    borderRadius: '1rem',
                    }}>
                <div className="store-icon" />
                <h4>Chiptos Mint</h4>
                <h6>Please use a Web3-enabled browser.</h6>
                
                <div className="store-links">
                    <a href={dataset.etherscan} className="store-link etherscan" />
                    <a href={dataset.opensea} className="store-link opensea" />
                    <a href={dataset.looksrare} className="store-link looksrare" />
                </div>
            </div>

            </> 
            : <>
            

            <div className="store-info">
                <div className="store-icon" />
                <h4>Chiptos Mint</h4>
                <div className="store-stats">
                    <div className="store-stat">
                        <h5>Mint Price</h5>
                        <p>{dataset.price}</p>
                    </div>
                    <div className="store-stat">
                        <h5>Total Supply</h5>
                        <p>7680</p>
                    </div>
                    <div className="store-stat">
                        <h5>Total Remaining</h5>
                        <p>{remaining && remaining}</p>
                    </div>
                </div>
                <div className="store-links">
                    <a href={dataset.etherscan} className="store-link etherscan" />
                    <a href={dataset.opensea} className="store-link opensea" />
                    <a href={dataset.looksrare} className="store-link looksrare" />
                </div>
            </div>



            <div className="store-row-container">
                <div className="store-row"  style={{height: isPresaleActive ? '33.3%' : '50%'}}>
                    <div className='store-row-heading'>Holder Free Claim</div>
                        <button 
                            className='claim-free-btn'
                            onClick={claimFree} 
                            style={{
                                // paddingTop: '.5rem',
                                pointerEvents: freeRemaining > 0 ? 'auto' : 'none',
                                background: freeRemaining > 0 ? 'rgb(49, 84, 226)' : 'rgb(100,100,100)',
                            }} 
                        >Claim Free{` `}<p style={{marginLeft: '.1rem'}}>({freeRemaining} Available)</p></button>
                </div>

                <div className="store-row" style={{height: isPresaleActive ? '33.3%' : '50%'}}>
                    <div className='store-row-heading'>Holder Pre-sale</div>
                    <div className="store-sub-row">
                        <div>
                        <div className="input-container">
                            <button 
                                className='round-button' 
                                onClick={dec_holder}
                                style={{
                                    pointerEvents: maxHolderAvalable > 0 ? 'auto' : 'none',
                                    background: maxHolderAvalable > 0 ? 'rgb(49, 84, 226)' : 'rgb(100,100,100)',
                                }}
                            >-</button>
                            <div className='value'> {holderSaleAmount}</div>
                            <button 
                                className='round-button' 
                                onClick={inc_holder}
                                style={{
                                    pointerEvents: maxHolderAvalable > 0 ? 'auto' : 'none',
                                    background: maxHolderAvalable > 0 ? 'rgb(49, 84, 226)' : 'rgb(100,100,100)',
                                }}
                            >+</button>
                        </div>
                        </div>
                        <button 
                            onClick={mintHolder}
                            style={{
                                pointerEvents: maxHolderAvalable > 0 ? 'auto' : 'none',
                                background: maxHolderAvalable > 0 ? 'rgb(49, 84, 226)' : 'rgb(100,100,100)',
                            }}
                        >Mint</button>

                    </div>
                        <p className='subtext'>Remaining: {maxHolderAvalable}</p>
                </div>



            {isPresaleActive && (
                <>
                {isPreSale ? (
                    <div className="store-row">
                    <div className='store-row-heading'>Pre-sale + Reserve List</div>
                        <div className="store-sub-row">
                            <div className="input-container">
                                <button 
                                style={{
                                    pointerEvents: isWhitelisted ? 'auto' : 'none',
                                    background: isWhitelisted ? 'rgb(49, 84, 226)' : 'rgb(100,100,100)',
                                }}
                                className='round-button' onClick={dec_presale}>-</button>
                                <div className='value'> {preSaleAmount}</div>
                                <button 
                                 style={{
                                    pointerEvents: isWhitelisted ? 'auto' : 'none',
                                    background: isWhitelisted ? 'rgb(49, 84, 226)' : 'rgb(100,100,100)',
                                }}
                                className='round-button' onClick={inc_presale}>+</button>
                            </div>
    
                            <button 
                            onClick={mintPreSale}
                            style={{
                                pointerEvents: isWhitelisted ? 'auto' : 'none',
                                background: isWhitelisted ? 'rgb(49, 84, 226)' : 'rgb(100,100,100)',
                            }}
                            >Mint</button>
    
                        </div>
                       {isWhitelisted 
                            ? <p className='subtext'>Remaining: {maxPresaleAvailable}</p>
                            : <p className='subtext'>You are not on the pre-sale list.</p>
                       }
                    </div>
                ) : (
                    <div className="store-row">
                        <div className='store-row-heading'>Public Sale</div>
                        <div className="store-sub-row">
                                <div className="input-container">
                                    <button className='round-button' onClick={dec_public}>-</button>
                                    <div className='value'> {publicSaleAmount}</div>
                                    <button className='round-button' onClick={inc_public}>+</button>
                                </div>

                            <button onClick={mintPublicSale}>Mint</button>

                        </div>
                        <p className='subtext'>Max: 10 per TX.</p>
                    </div>
                )}
                </>
            )}






            </div>
            </>}
        </div>
    )

}

export default Store