import * as React from 'react'
import dataset from '../dataset.js'
import styled from 'styled-components'

const SFooter = styled.div`
    background: #2222;
    width: 100vw;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    position: absolute;
    bottom: 0;
`
const SFooterSection = styled.div`
    display: flex;
    // border: 1px solid yellow;
`



const Footer = (props:any) => {
    return(
        <SFooter className='footer'>
            <SFooterSection>
                <a href={dataset.aboutLink} className='footer-link'>About</a>
                <a href={dataset.docsLink} className='footer-link'>Docs</a>
                <a href={dataset.visionLink} className='footer-link'>Vision</a>
            </SFooterSection>
            <SFooterSection>
                <a href={dataset.twitter} className='icon twitter-icon' />
                <a href={dataset.discord} className='icon discord-icon' />
            </SFooterSection>
        </SFooter>
    )
}

export default Footer