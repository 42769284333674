import * as React from 'react'
import styled from 'styled-components'

const SCard = styled.div`
    background: #222a;
    padding: 2rem;
    border-radius: 1rem;
`


const Denial = (props:any) => {
    return(
        <SCard>
            <h3>You are not on the whitelist.</h3>
            <p>Please return after the official opening</p>
        </SCard>
    )
}

export default Denial