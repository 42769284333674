import * as React from "react";
import * as ReactDOM from "react-dom";
import { createGlobalStyle } from "styled-components";
import { Toaster } from 'react-hot-toast'
// tslint:disable-next-line
// @ts-ignore
import App from "./App";
import { globalStyle } from "./styles";
const GlobalStyle:any = createGlobalStyle`
  ${globalStyle}
`;

declare global {
  interface Window {
    web3: any;
    // tslint:disable-next-line
    // @ts-ignore
    ethereum: any;
    Web3Modal: any;
    Box: any;
    box: any;
    space: any;
  }
}

ReactDOM.render(
  <>
  <Toaster 
    toastOptions={{
      // Define default options
      duration: 30_000,
      style: {
        background: '#363636',
        color: '#fff',
      },
      // Default options for specific types
      success: {
        duration: 10_000,
        style: {
          background: '#000',
          color: 'green',
          border: '2px solid green'
        }
      },
      error: {
        duration: 10_000,
        style: {
          background: '#000',
          color: 'red',
          border: '2px solid red'
        }
      },
    }}
  />
    <GlobalStyle />
    <App />
  </>,
  document.getElementById("root")
);
